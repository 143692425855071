#accountAvatars, #accountAvatarPopup {
    .swiper-wrapper {
        align-items: center;
    }

    .swiper-slide {
        img {
            transition: transform 0.3s ease, width 0.3s ease;
            width: 80px;
            margin: 0 auto;
        }
    }

    .swiper-slide-active {
        width: 144px;
        transform: scale(1);

        img {
            transition: border 0.2s ease;
            max-width: 144px;
            width: 100%;
            border: 5px solid #E66800;
        }
    }
}
