#latestUpdates {
    .swiper-slide {
        height: auto;

        @media (max-width: 640px) {
            width: 70%
        }
    }
}

#heroBattleLeaderboard {
    .swiper {
        height: 256px;
        padding-right: 20px;
        margin-right: -10px;
    }

    .swiper-slide {
        height: auto !important;
    }

    .swiper-scrollbar {
        width: 6px;
        margin-left: -10px;
        margin-top: -1px;
        background: rgba(255, 255, 255, 0.2);

        @media (max-width: 640px) {
            margin-left: 0;
            right: 8px;
        }
    }

    .swiper-scrollbar-drag {
        background: #ffffff;
    }
}
