@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Londrina+Solid:wght@100;300;400;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .btn {
        @apply inline-block min-w-28 rounded-lg bg-primary-500 font-semibold text-center content-center align-middle text-white uppercase;

        &.btn-small {
            @apply border max-w-28 py-2 text-sm;
        }

        &.btn-primary {
            @apply w-full max-w-92 h-16 lg:py-4 border-2 text-xl;
        }

        &.btn-primary-auto {
            @apply h-auto w-full max-w-92 border-2 text-xl py-2 sm:py-3;
        }

        &.btn-big {
            @apply w-full max-w-135 h-auto md:h-19 py-2 text-xl;
        }

        &.btn-light-blue {
            @apply bg-light-blue-500;

            &:hover {
                @apply bg-light-blue-200 shadow-md;
            }

            &:focus {
                @apply bg-light-blue-600 shadow-inner;
            }
        }

        &.btn-orange {
            @apply bg-orange-500 border-hidden;

            &:hover {
                @apply bg-yellow-500 border-hidden shadow-bottom;
            }

            &:focus {
                @apply bg-orange-600 border-orange-600 shadow-focus;
            }
        }

        &.btn-main {
            @apply bg-primary-500;

            &:hover {
                @apply bg-primary-300;
            }

            &:focus {
                @apply bg-primary-600 shadow-inner;
            }
        }

        &.btn-blue {
            @apply bg-cyan-500;

            &:hover {
                @apply bg-cyan-300;
            }

            &:focus {
                @apply bg-cyan-600 shadow-inner;
            }
        }

        &.btn-white {
            @apply bg-white text-cyan-500 border-hidden;

            &:hover {
                @apply text-cyan-300 shadow-md;
            }

            &:focus {
                @apply bg-primary-100 border-primary-100 text-cyan-500 shadow-focus;
            }
        }

        &.btn-gray {
            @apply bg-primary-50 text-primary-500;

            &:hover {
                @apply text-primary-300 shadow-md;
            }

            &:focus {
                @apply bg-primary-100 border-hidden text-primary-50 shadow-focus;
            }
        }

        &.btn-transparent {
            @apply border-white bg-transparent;

            &:hover {
                @apply bg-primary-300 shadow-md;
            }

            &:focus {
                @apply bg-primary-600 shadow-inner;
            }
        }

        &.btn-transparent-gray {
            @apply h-auto w-full max-w-92 border-2 text-xl bg-transparent text-primary-500 border-black py-2 sm:py-3;

            &:hover {
                @apply bg-primary-300 text-white shadow-md;
            }

            &:focus {
                @apply bg-primary-600 text-white shadow-inner;
            }
        }
    }

}

.popup-leaderboards {
    .swiper {
        height: 237px;
        padding-right: 20px;
        margin-right: -10px;

        @media (max-width: 640px) {
            height: 220px;
        }
    }

    .swiper-slide {
        height: auto !important;
    }

    .swiper-scrollbar {
        width: 6px;
        margin-right: -5px;
        margin-top: -2px;
        background: rgba(128, 144, 166, 0.5);

        @media (max-width: 640px) {
            margin-left: 0;
            right: 5px;
        }
    }

    .swiper-scrollbar-drag {
        background: rgba(123, 133, 156, 1);
    }
}
