#promotionSlider,
#factLabSlider,
#popularOnTheInternet {
    .swiper-slide {
        height: auto;

        @media (max-width: 640px) {
            width: 70%
        }
    }
}

.battle-end-leaderboard {
    .swiper-wrapper {
        height: 220px !important;
    }

    .swiper-slide {
        height: auto !important;
    }

    .swiper-scrollbar {
        width: 6px;
        margin-right: -17px;
        margin-top: -2px;
        background: rgba(128, 144, 166, 0.5);

        @media (max-width: 640px) {
            margin-left: 0;
            right: 5px;
        }
    }

    .swiper-scrollbar-drag {
        background: rgba(123, 133, 156, 1);
    }
}
